<template>
  <!-- star -->

  <div class="kejipage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerImg="Img1"></banner2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div style="font-size: 20px;">当前位置: 产品介绍><span>Iot</span></div>
    </div>
    <div class="content">
      <div class="content_item">
        <h2>共享电动自行车滑板车4G中控S1</h2>
        <div class="item_ct">
          <el-image class="item_ct_left" :src="require('../assets/img/product/iot.png')" :preview-src-list="s1Img">
          </el-image>
          <img class="item_ct_right" src="../assets/img/product/S1.png" alt="">
        </div>
      </div>
      <div class="content_item">
        <h2>共享电动自行车滑板车4G中控S2</h2>
        <div class="item_ct">
          <el-image class="item_ct_left" :src="require('../assets/img/product/iot2.png')" :preview-src-list="s2Img">
          </el-image>
          <img class="item_ct_right" src="../assets/img/product/S2.png" alt="">
        </div>
      </div>
    </div>
    <footer2></footer2>
  </div>
</template>


<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav2.vue";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
  },
  data () {
    return {
      h1: "公司产品",
      s1Img: [
        require('../assets/img/product/iot.png')
      ],
      s2Img: [
        require('../assets/img/product/iot2.png')
      ],
      Img1: require("../assets/img/banner1.png"),
    };
  },
  methods: {},
};
</script>
<style scoped>
@charset "utf-8";

/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}

/*去内外边距*/
html,
body {
  font-family: '微软雅黑', '宋体', Arail, Tabhoma;
  text-align: left;
}

/*字体 文本居左*/
ul {
  list-style: none;
}

/*去ul li 小点*/
img {
  border: 0 none;
}

/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}

/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}

/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}

/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}

/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
}

/*清浮动*/
a {
  color: #000000;
}

.kejipage_wrap {
  width: 100%;
  /* width: 1920px; */
  margin: 0 auto;
  height: 1000px;
}

/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}

.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}

.Crumbs div {
  line-height: 46px;
}

.Crumbs span {
  color: #000000;
}
.content {
  /* border: 1px solid orange; */
  width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
  margin-bottom: 40px;
}
.item_ct {
  display: flex;
  justify-content: center;
  align-items: center;
}
.item_ct_left {
  width: 500px;
}
.item_ct_right {
  width: 900px;
}
</style>